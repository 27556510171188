import React from 'react'

const footerLinks = [
  {
    "text": "Home",
    "url": null
  },
  {
    "text": "Meet The Team",
    "url": "team"
  },
  {
    "text": "Our Services",
    "url": "services"
  },
  {
    "text": "Why Visit?",
    "url": "whyvisit"
  },
  {
    "text": "Treatments & Cosmetic",
    "url": "treatments"
  },
  {
    "text": "Dental Membership",
    "url": "membership"
  },
  {
    "text": "Contact",
    "url": "contact"
  },
  {
    "text": "Fee Guide",
    "url": "info/feeguide"
  },
  {
    "text": "Links",
    "url": "info/links"
  }
]


export const Footer = () => {
  return (
    <footer id="footer">
      <div id="footer-inner">
        <div id="footer-links" className="clearfix">
          <ul>
            {footerLinks.map((_link, idx) => (
              <li key={idx}>
                <a href={`/${_link.url?_link.url:''}`}>{_link.text}</a>
              </li>
            ))}
          </ul>
        </div>
        <div id="createdby">Website design by BowBelle and developed by <a href="http://jimlevett.com">Jim Levett</a>
        </div>
      </div>
    </footer>
  )
}