import PropTypes from "prop-types"
import React from "react"
import {Menu} from "./menu";
import imageLogo from '../images/pipers_logo.png'

const Header = ({ headerImage, pageTitle, topImageHTML }) => {
  return (
    <header className="site-header">
      <div id="nav-container" className="clearfix">
        <Menu pageTitle={pageTitle} />
      </div>
      <div className="below-nav clearfix">
        <div className="logo">
          <img id="logo-img" src={imageLogo} alt="Pipers Dental Practice"
               title="Pipers Dental Practice"/><br/>
          <div className="address">
        <span className="address">
          <span className="address-line">42A Station Road East</span>
          <span className="address-line">Oxted Surrey RH8 0PG</span>
        </span>
            <span className="telephone">Tel: 01883 712486</span><br/>
            <span className="email"><a href="mailto:piperdental@btconnect.com"
                                       title="Click here to Email Us">piperdental@btconnect.com</a></span>
          </div>
        </div>
        <div className="top-image">
          {headerImage?<img src={headerImage} alt="Header" />:null}
        </div>
      </div>

    </header>
  )
}



Header.propTypes = {
  siteTitle: PropTypes.string,
  pageTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
  pageTitle: ``
}

export default Header
