/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../style/main.scss"
import Layout from './layout'

const LightboxLayout = ({ children, ...props }) => {
  return (<Layout {...props}>{children}</Layout>)
}

LightboxLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LightboxLayout
