import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>PERIDONTAL (GUM) DISEASES</h2>
      <h3>What are they?</h3>
      <p>The simplest form of gum disease, gingivitis, is often a reaction to a build up of plaque on the teeth. Plaque is a soft, sticky, substance made up of bacteria which live on the sugars from things we eat and drink. Plaque can build up in difficult-to-clean areas, especially at the contact areas between the teeth. It is important to clean these areas daily with floss, tape or small interdental "bottle" brushes.</p>
      <p>The earliest sign of disease is bleeding of the gums. They may also look red or swollen. Gingivitis can often be cured simple with good mouth hygiene - brushing twice a day and using other mouth hygiene aids, such as floss and a medicated mouthwash, as advised by your dentist or hygienist. Occasionally, even in healthy mouths, gums may become infected or sore and you may notice an unpleasant metallic taste. This is acute gingivitis and you should seek urgent treatment.</p>
      <p>As the disease progresses the tissues holding  teeth in place start to break  down and pockets  in the gum form around  the teeth which allow even more plaque  to gather. This stage is called chronic periodontitis. It is usually painless and can become quite severe if not treated resulting in teeth becoming loose, appearing to move  position  or to fall out.</p>
      <p>Symptoms to watch out for are:</p>
      <ul>
        <li>gums that have come away from teeth</li>
        <li>pus between the teeth and gums</li>
        <li>persistent bad breath  or a bad taste</li>
        <li>permanent teeth that are loose or are changing position.</li>
      </ul>
      <p>Scaling and polishing by a dentist or hygienist can remove tartar and stains. This will help you to keep your mouth clean.  In more severe cases of gum disease, deep cleaning below the gumline by a dentist or hygienist may be necessary. Occasionally surgery is required in which the gum is reshaped under local anaesthetic, to allow affected areas to be treated.</p>

      <p>If gum disease has progressed too far, the tooth or teeth involved may have to be removed.</p>
      <h3>Who gets gum disease?</h3>
      <p>Gum disease can start when you are a child,  but chronic periodontitis is normally only a problem in adults.</p>
      Some people are more likely to have periodontal disease than others:
      <ul>
        <li>crooked teeth are more difficult to keep clean, so the gums supporting  such teeth might be more prone to gum disease.</li>
        <li>smoking makes  gum disease considerably worse. Quitting smoking is important for your general  and mouth health.</li>
        <li>certain drugs and medicines can affect your gums:  ask your dentist  about these.</li>
        <li>diabetes and some other diseases  reduce people's  resistance to gum disease.  People who have these conditions need to be especially careful about their mouth hygiene.</li>
        <li>existing gum disease can be worsened by hormonal changes, due to pregnancy or oral contraceptives ("the pill"). Here again, good hygiene is important.</li>
      </ul>
    </LightboxLayout>
  )
}

export default Page